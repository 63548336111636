import React from 'react';
import './style.css'
const Home = () => {
  return (
    <div className='welcome-page'>
      <img src='/home/logo.png' alt='spivdiia logo'></img>
    </div>
  );
};

export default Home;